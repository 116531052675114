import React from "react";
import Branding from "./Branding";
import { SectionTitle } from "./SectionTitle";
import { Link } from "react-router-dom";
import { Location01Icon, Mail01Icon, SmartPhone01Icon } from "hugeicons-react";
import BottomBar from "./BottomBar";

const PageFooterV3 = () => {
  const handleClick = (e, path) => {
    e.preventDefault();
    const targetId = path.split("#")[1];
    const targetSection = document.getElementById(targetId);

    if (targetSection) {
      const sectionPosition =
        targetSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: sectionPosition - 100, behavior: "smooth" });
      // displayMenu()
    }
  };
  return (
    <footer className="footer-v3">
      <div className="container">
        <div className="company">
          <Branding />
          <p>
            Welcome to CSR Job Match, where we bridge the gap between job
            seekers and employers. Discover your next career opportunity or find
            the ideal candidate with ease.
          </p>
        </div>
        <div className="links">
          <SectionTitle mainTitle={"Links"} />
          <ul>
            <li>
              <Link onClick={(e) => handleClick(e, "/#forCandidates")}>
                For talents
              </Link>
            </li>
            <li>
              <Link onClick={(e) => handleClick(e, "/#forBusiness")}>
                For business
              </Link>
            </li>
            <li>
              <Link onClick={(e) => handleClick(e, "/#features")}>
                Features
              </Link>
            </li>
          </ul>
        </div>

        <div className="contacts">
          <SectionTitle mainTitle={"Contacts"} />

          <ul>
            <li>
              <Link>
                <Mail01Icon size={24} variant={"stroke"} />
                info@csrlimited.com
              </Link>
            </li>
            <li>
              <Link>
                <SmartPhone01Icon size={24} variant={"stroke"} />
                +250791902159
              </Link>
            </li>
            <li>
              <Link>
                <Location01Icon size={24} variant={"stroke"} />
                KG 175 Street, Kigali, Gate 37
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <BottomBar />
    </footer>
  );
};

export default PageFooterV3;
