import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import api, { API_URL } from "../../api";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../constants";
import { useNotification } from "../../components/services/notificationContext";
import BrandIcon from "../../components/services/brandIcon";
import Branding from "../../components/v3/Branding";
import { Loading03Icon } from "hugeicons-react";
import { getUserData } from "../../services/getAccountData";

const LoginPage = () => {
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showForgoPassword, setShowForgoPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSingIn = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setIsLoading(true);

    if (!username || !password) {
      showNotification("fail", "Please fill all fields");
      setIsLoading(false);
      return;
    }
    try {
      const credentials = {
        username: username,
        password: password,
      };
      const response = await api.post(
        `${API_URL}/accounts/token/`,
        credentials
      );
      if (response.status === 200) {
        localStorage.setItem(ACCESS_TOKEN, response.data.access);
        localStorage.setItem(REFRESH_TOKEN, response.data.refresh);

        getUserData();
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        showNotification("fail", error.response.data.error || "Error");
      }
      setShowForgoPassword(true);
      showNotification("fail", "An error happened");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <title>Login | jobMatch</title>
      </Helmet>

      <div className="page-content">
        <div className="container auth-forms">
          <Branding />
          <p>
            <strong>Login</strong> into CSR Staffing with your email and
            password
          </p>

          <form action="" className="loginForm">
            {error && <div className="error-message">{error}</div>}
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="email"
              name="email"
              id="email"
              placeholder="Your email"
            />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
              placeholder="Your password"
            />
            <button
              type="button"
              onClick={(e) => handleSingIn(e)}
              className="button primary-button"
            >
              {isLoading ? (
                <Loading03Icon className="loading-icon" size={20} />
              ) : (
                "Login"
              )}
            </button>

            {showForgoPassword ? (
              <p className="form-link-container">
                Forgot password?{" "}
                <span className="form-link">
                  <Link to="/forgot-password/">Reset it</Link>{" "}
                </span>
              </p>
            ) : (
              ""
            )}

            <p className="form-link-container">
              Don't have an account yet?{" "}
              <span className="form-link">
                <Link to="/register">Register</Link>{" "}
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
