import React from "react";

const Development = () => {
  return (
    <div className="development-container">
      <div className="wrapper">
        <h1>"Login Successful!"</h1>
        <p>
          You have successfully logged in, but the dashboard is still under
          development.
        </p>
        <div className="actions">
          👉 <a href="/">Click here</a> to return to the home page.
        </div>
      </div>
    </div>
  );
};

export default Development;
