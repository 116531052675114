import api, { API_URL } from "../api";

export const getUserData = async (refresh) => {
  const response = await api.get(`${API_URL}/accounts/user/`);
  if (response.status === 200) {
    console.log(response.data);
    localStorage.setItem("user", JSON.stringify(response.data.user));
    localStorage.setItem("account_type", response.data.account_type);

    if (refresh) {
      window.location.reload();
      localStorage.setItem("underDevelopment", true);
    } else {
      if (response.data.account_type === "candidate") {
        window.location.href = "/dashboard/candidates/";
      } else if ((response.data.account_type = "business")) {
        window.location.href = "/dashboard/business/";
      } else {
        window.location.href = "/register/";
      }
    }
  }
};
