import React from "react";

const IsLoading = ({ text }) => {
  return (
    <div className="loading-icon-text">
      <img
        className="loading-icon"
        src={require("../assets/icons/loading-yellow.png")}
      ></img>
      <p>{text}</p>
    </div>
  );
};

export default IsLoading;
